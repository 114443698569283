import { useState, useContext, useEffect } from "react";
import RoundContext from '../contexts/RoundContext';

export default function Table(){
    const [textDisplay, setTextDisplay] = useState(0);
    const [isBetPlaced, setIsBetPlaced] = useState(false);
    const [isNewGame, setIsNewGame] = useState(true);

    const a = useContext(RoundContext);

    const newGame = () => {
        setTextDisplay(1000);
        setIsNewGame(false);
        a.resetStateVariables();
        setIsBetPlaced(false);
    };    
    const placeBet = () => {
        setIsBetPlaced(true);
        a.updateStartGame(textDisplay);
    };    

    const dealerPoint = a.valueObject.dealerPoints;
    const playerPoint = a.valueObject.playerPoints;
    useEffect(()=>{
        if(dealerPoint!==0 && playerPoint!==0)
        {
            console.log('useEffect from Table called. Dealer Point - '+dealerPoint+ 'Player Point - '+playerPoint);
            if(dealerPoint > playerPoint){
                setTextDisplay("LOST");
                a.updateWinner("dealer");
            }
            else if(dealerPoint < playerPoint){
                setTextDisplay("WON");
                a.updateWinner("player");
            }
            else{
                setTextDisplay("PUSH");
                a.updateWinner("none");
            }
        }
    },[dealerPoint, playerPoint])

    const winnerDeclare = a.valueObject.winner;
    useEffect(()=>{
        if(winnerDeclare)
        {
            setTimeout(()=>{
                setIsNewGame(true);
            },2000);
        }
    },[winnerDeclare])

    return (
        <>
            <div className="tableBox">
                <button onClick={newGame} className={isNewGame? "button inline" : "disableButton inline"} disabled={!isNewGame}>New Game</button>
                <button onClick={() => {(textDisplay+100 >= 10000)? setTextDisplay(10000) : setTextDisplay(textDisplay + 100)}} className={(!isNewGame && !isBetPlaced && (textDisplay < 10000))? "button inline" : "disableButton inline"} disabled={!(!isNewGame && !isBetPlaced && (textDisplay < 10000))}>+100</button>
                <button onClick={() => {(textDisplay+500 >= 10000)? setTextDisplay(10000) : setTextDisplay(textDisplay + 500)}} className={(!isNewGame && !isBetPlaced && (textDisplay < 10000))? "button inline" : "disableButton inline"} disabled={!(!isNewGame && !isBetPlaced && (textDisplay < 10000))}>+500</button>
                <button onClick={() => {(textDisplay+1000 >= 10000)? setTextDisplay(10000) : setTextDisplay(textDisplay + 1000)}} className={(!isNewGame && !isBetPlaced && (textDisplay < 10000))? "button inline" : "disableButton inline"} disabled={!(!isNewGame && !isBetPlaced && (textDisplay < 10000))}>+1000</button>
                <h4 className="inline">{textDisplay}</h4>
                <button onClick={placeBet} className={(isNewGame || isBetPlaced)? "disableButton inline" : "button inline"}>Place Bet</button>
                <button onClick={() => setTextDisplay(textDisplay - 100)} className={(!isNewGame && !isBetPlaced && (textDisplay > 1000))? "button inline" : "disableButton inline"} disabled={!(!isNewGame && !isBetPlaced && (textDisplay > 1000))}>-100</button>
            </div>
        </>
    );
}