//import playerPng from '../images/player.png'
import playerPng from '../images/demoPlayer3.png'
import Chips from '../images/chips.svg'
import PlayerGame from './PlayerGame';
import RoundContext from '../contexts/RoundContext';
import { useEffect, useState, useContext } from 'react';

export default function Player(){
    const a = useContext(RoundContext);
    const [playerChips,setPlayerChips] = useState(100000);
    const betAmnt = a.valueObject.betAmount;

    useEffect(()=>{
        if(betAmnt){
            const newPlayerChips = Number(playerChips) - Number(betAmnt);
            setPlayerChips(newPlayerChips);
        }
    },[betAmnt]);

    const winnerDeclare = a.valueObject.winner;
    useEffect(()=>{
        if(winnerDeclare)
        {
            if(winnerDeclare === "player")
            {
                const newPlayerChips = Number(playerChips) + (Number(betAmnt)*2);
                setPlayerChips(newPlayerChips);
            }
            else if(winnerDeclare === "none")
            {
                const newPlayerChips = Number(playerChips) + Number(betAmnt);
                setPlayerChips(newPlayerChips);
            }
        }
    },[winnerDeclare])

    return (
        <>
            <div className="playingBox inline">
            <div className='playingBoxProfile'>
                    <img src={playerPng} alt="PLAYER" className='image'/>
                    <h3>Player</h3>
                    <div className='chips inline'>
                        <img src={Chips} alt='CHIPS' className='chipsImg inline'/>
                        <div className='chipsAmt inline'>{playerChips}</div>
                    </div>
                </div>
                <div className='playingBoxCards inline'>
                    <div><PlayerGame /></div>
                </div>
            </div>            
        </>
    );
}