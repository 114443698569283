import { useState } from "react";
import RoundContext from "./RoundContext";

const RoundState = (props)=>{
    const valueProvider = {
        startGame: false,
        dealerTurn: false,
        playerPoints: 0,
        dealerPoints: 0,
        betAmount: 0,
        winner: "",
        reset: false
    }   
    const [valueObject, setValueObject] = useState(valueProvider);
    const resetStateVariables = () =>{
        setValueObject({
            ...valueProvider,
            reset: true
        });
    }
    const updateStartGame = (props) =>{
        setValueObject({
            ...valueObject,
            startGame: true,
            betAmount: props, 
            reset: false
        });
    }
    const updateDealerTurn = (props) =>{
        setValueObject({
            ...valueObject,
            dealerTurn: true,
            playerPoints: props 
        });
    }
    const handleBust = (props) =>{
        const { dealerP, playerP } = props;
        setValueObject({
            ...valueObject,
            playerPoints: playerP,
            dealerPoints: dealerP
        });
    }
    const updateDealerPoints = (props) =>{
        setValueObject({
            ...valueObject,
            dealerPoints: props
        });
    }
    const updateWinner = (props) =>{
        setValueObject({
            ...valueObject,
            winner: props
        });
    }

    return (
        <RoundContext.Provider value={{valueObject, updateStartGame, updateDealerTurn, updateDealerPoints, updateWinner, resetStateVariables, handleBust}}>
            {props.children}
        </RoundContext.Provider>
    )
}

export default RoundState;