import Card from './Card';
import { useState, useContext, useEffect, useRef } from "react";
import RoundContext from '../contexts/RoundContext';
import CalculatePoints from './CalculatePoints';

export default function DealerGame(){

    const [dealerCards, setDealerCards] = useState([]);
    const pointsRef = useRef(0);
    const numberOfAcesRef = useRef(0);

    const AddCard = () => {
        const { cardComponent, cardValue } = Card();
        setDealerCards(prevDealerCards => [...prevDealerCards, {cardComponent}]);
        
        let tempNumberOfAces = numberOfAcesRef.current;
        if (cardValue === 'A') {
            tempNumberOfAces +=1;            
        }
        let newPoints = CalculatePoints({ propPoints: pointsRef.current, propCardValue: cardValue });
        while (tempNumberOfAces > 0 && newPoints > 21) {
            newPoints -= 10;
            tempNumberOfAces -=1;
        }
        pointsRef.current = newPoints;
        numberOfAcesRef.current = tempNumberOfAces;
    };
    const a = useContext(RoundContext);
    const gameStarted = a.valueObject.startGame;
    const dealerGameStarted = a.valueObject.dealerTurn;
    const resetVariables = a.valueObject.reset;

    useEffect(()=>{
        if(gameStarted){
        setTimeout(()=>{
            AddCard();
        },1000);
        }
    },[gameStarted])

    useEffect(()=>{
        if(dealerGameStarted){
        const intervalId = setInterval(() => {
            if (pointsRef.current < 17) {
                AddCard();
            } 
            else if (pointsRef.current >= 17 && pointsRef.current <= 21){
                clearInterval(intervalId);
                a.updateDealerPoints(pointsRef.current);
            }
            else{
                clearInterval(intervalId);
                a.updateDealerPoints(-1);
            }
        }, 1000);
        return () => clearInterval(intervalId);
        } 
    },[dealerGameStarted])

    useEffect(()=>{
        if(resetVariables){
            pointsRef.current = 0;
            setDealerCards([]);
            numberOfAcesRef.current = 0;
        }
    },[resetVariables])
    return (
        <>
            <div>
                {dealerCards.map((elementCard,index) => (
                    <div className='inline' key={index}>{elementCard.cardComponent}</div>
                ))}
            </div>
            <div className='pointDisplay inline'>
                <h4>{pointsRef.current}</h4>
            </div>   
        </>
    );
}