import Card from './Card';
import { useState, useContext, useEffect, useRef } from "react";
import RoundContext from '../contexts/RoundContext';
import CalculatePoints from './CalculatePoints';

export default function PlayerGame(){

    const [playerCards, setPlayerCards] = useState([]);
    const [allowHit, setAllowHit] = useState(false);
    const pointsRef = useRef(0);
    const numberOfAcesRef = useRef(0);

    const AddCard = () => {
        const { cardComponent, cardValue } = Card();
        setPlayerCards(prevPlayerCards => [...prevPlayerCards, { cardComponent }]);
        
        let tempNumberOfAces = numberOfAcesRef.current;
        if (cardValue === 'A') {
            tempNumberOfAces +=1;            
        }
        let newPoints = CalculatePoints({ propPoints: pointsRef.current, propCardValue: cardValue });
        while (tempNumberOfAces > 0 && newPoints > 21) {
            newPoints -= 10;
            tempNumberOfAces -=1;
        }
        pointsRef.current = newPoints;
        numberOfAcesRef.current = tempNumberOfAces;
    };
    
    const a = useContext(RoundContext);
    const gameStarted = a.valueObject.startGame;
    const resetVariables = a.valueObject.reset;

    useEffect(()=>{
        if(gameStarted){
        setTimeout(()=>{
            AddCard();
        },500);
        
        setTimeout(()=>{
            AddCard();
            setAllowHit(true);
        },1500);
        } 
    },[gameStarted])

    const handleHitClick = () => {
        setTimeout(()=>{
            AddCard();
        },200);
    };

    const handleStandClick = () => {
        a.updateDealerTurn(pointsRef.current);
        setAllowHit(false);
    };

    useEffect(()=>{
        if(resetVariables){
            pointsRef.current = 0;
            setPlayerCards([]);
            numberOfAcesRef.current = 0;
        }
    },[resetVariables])

    useEffect(()=>{
        if(pointsRef.current){
            if(pointsRef.current > 21)
                {
                    a.handleBust({dealerP: 100, playerP: -1});
                    setAllowHit(false);
                }
        }
    },[pointsRef.current])

    return (
        <>
            <div>
                {playerCards.map((elementCard , index) => (
                    <div className='inline' key={index}>{elementCard.cardComponent}</div>
                ))}
            </div>
            <div className='inline'>
                <div className='pointDisplay inline'>
                    <h4>{pointsRef.current}</h4>
                </div>
                <button className={allowHit? "button inline" : "disableButton inline"} onClick={handleHitClick} disabled={!allowHit}>Hit</button>
                <button className={allowHit? "button inline" : "disableButton inline"} onClick={handleStandClick} disabled={!allowHit}>Stand</button>
            </div>
        </>
    );
}