export default function CalculatePoints(props) {
    const cardValue = props.propCardValue;
    const pointMapping = {
        'A': 11,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
        '10': 10,
        'J': 10,
        'Q': 10,
        'K': 10,
    };

    const currPoints = Number(props.propPoints) + (pointMapping[cardValue]);

    return currPoints;
}
