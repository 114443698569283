import './App.css';
import Dealer from './components/Dealer';
import Player from './components/Player';
import Table from './components/Table';
import RoundState from './contexts/RoundState';

export default function Game(){
  return (
    <>
    <RoundState>
      <div><Dealer /></div>
      <div><Table /></div>
      <div><Player /></div>
    </RoundState>
    </>
  );
}