//import dealerPng from '../images/dealer.png'
import dealerPng from '../images/demoDealer6.png'
import Chips from '../images/chips.svg'
import DealerGame from './DealerGame';
import RoundContext from '../contexts/RoundContext';
import { useEffect, useState, useContext } from 'react';

export default function Dealer(){
    const a = useContext(RoundContext);
    const [dealerChips,setDealerChips] = useState(100000);
    
    const betAmnt = a.valueObject.betAmount;
    const winnerDeclare = a.valueObject.winner;
    useEffect(()=>{
        if(winnerDeclare)
        {
            if(winnerDeclare === "dealer")
            {
                const newDealerChips = Number(dealerChips) + Number(betAmnt);
                setDealerChips(newDealerChips);
            }
            else if(winnerDeclare === "player")
            {
                const newDealerChips = Number(dealerChips) - Number(betAmnt);
                setDealerChips(newDealerChips);
            }
        }
    },[winnerDeclare])

    return (
        <>
            <div className="playingBox inline">
                <div className='playingBoxProfile'>
                    <img src={dealerPng} alt="DEALER" className='image'/>
                    <h3>Dealer</h3>
                    <div className='chips inline'>
                        <img src={Chips} alt='CHIPS' className='chipsImg inline'/>
                        <div className='chipsAmt inline'>{dealerChips}</div>
                    </div>
                </div>
                <div className='playingBoxCards inline'>
                    <div><DealerGame /></div>                 
                </div>
            </div>            
        </>
    );
}