import Clubs from '../images/suits/clubs.svg';
import Diamonds from '../images/suits/diamonds.svg';
import Hearts from '../images/suits/hearts.svg';
import Spades from '../images/suits/spades.svg';
//import RoundContext from '../contexts/RoundContext';
//import { useContext } from 'react';

// let count = 0;
// const getV = () => {
//     if(count == 0 || count == 2)
//     {
//         count++;
//         return 0;
//     }
//     else{
//         count++;
//         return Math.floor(Math.random() * 13);
//     }
// }
let currentCardFromDecks = 0;
let pairsArray = [];
const buildDeck = () => {
    pairsArray = [];
    currentCardFromDecks = 0;
    console.log('Shuffling Deck');
    //Building 1 deck
    for(let v = 0; v <= 12; v++){
        for(let s = 0; s <= 3; s++){
            pairsArray.push([v,s]);
        }
    }
    //Duplicating Array 4 times
    pairsArray = [...pairsArray, ...pairsArray, ...pairsArray, ...pairsArray];
    // Shuffle the array using Fisher-Yates shuffle algorithm
    for (let i = pairsArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [pairsArray[i], pairsArray[j]] = [pairsArray[j], pairsArray[i]];
    }
}

export default function Card(){
    //const a = useContext(RoundContext);
    //const startGame = a.valueObject.startGame;

    const cardSuit = [Clubs, Diamonds, Hearts, Spades];
    const cardValue = ['A','2','3','4','5','6','7','8','9','10','J','Q','K'];
    //Math.floor(Math.random() * (max - min + 1)) + min
    //const v = getV();

    // const v = Math.floor(Math.random() * 13);
    // const s = Math.floor(Math.random() * 4);

    let v,s;
    if(currentCardFromDecks === 0 || currentCardFromDecks === 208)
    {
        buildDeck();
        [v,s] = pairsArray[currentCardFromDecks];
        currentCardFromDecks++;
    }
    else
    {
        [v,s] = pairsArray[currentCardFromDecks];
        currentCardFromDecks++;
    }

    return {
        cardComponent: (
            <>
            <div className="card">
                <div className="card-number" card-suit={s}>
                    {cardValue[v]}
                </div>
                <div className="bottom-suits-box">
                    <img src={cardSuit[s]} alt="suit"/>
                </div>
            </div>
            </>
        ),
        cardValue: cardValue[v]
    };
}
